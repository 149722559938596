<template>
  <div>404 Not Found</div>
</template>

<script>
import {services} from "../service/user-api";
import router from "../router";

export default {
  name: "Logout",
  async created() {
    await services.user.Logout();

    let {url,currentUrl} = this.$route.query;

    if(!url) {
      return router.push("/");
    }

    if(url.indexOf("?") === -1)
      url += "?"
    else
      url += "&"

    window.location.href = url + `currentUrl=${currentUrl}`;
  }
}
</script>